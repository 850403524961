import { checkPermission } from '@/utils'

export const tableOption = {
  search: checkPermission(['externaladmin:orderManage:cutoffBatch:search']),
  menuWidth: 188,
  editBtn: false,
  delBtn: false,
  searchLabelWidth: 110,
  column: [
    {
      label: '订单号',
      prop: 'orderCode',
      hide: true,
      search: true
    },
    {
      label: '运单号',
      prop: 'expressWaybillCode',
      hide: true,
      search: true
    },
    {
      label: '分销商',
      prop: 'orderDistributorName',
      hide: true,
      search: true
    },
    {
      label: '产品名称',
      prop: 'productCnName',
      hide: true,
      search: true
    },
    {
      label: '款式名称',
      prop: 'structName',
      hide: true,
      search: true
    },
    {
      label: '内部SKU',
      prop: 'inSku',
      hide: true,
      search: true
    },
    {
      label: '自定义SKU',
      prop: 'customSku',
      hide: true,
      search: true
    },
    {
      label: '货件编号',
      prop: 'shipmentNo',
      hide: true,
      search: true
    },
    {
      label: '截单批次',
      prop: 'batchName',
      searchWidth: 275,
      searchLabelWidth: 100
    },
    {
      label: '订单数',
      prop: 'orderCount',
      slot: true
    },
    /*{
      label: '刀版图状态',
      prop: 'isKnifeLayoutCreated',
      slot: true,
      search: true,
      type: 'select'
    },*/
    {
      label: '截单时间',
      prop: 'sendTime',
      hide: true,
      search: true,
      searchFormSlot: true,
      searchWidth: 555,
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      label: '操作员',
      prop: 'userName',
      searchWidth: 275,
      searchLabelWidth: 100
    },
    {
      label: '截单时间',
      prop: 'cutoffTime'
    }
  ]
}
